import React, { useState } from 'react'
import moment from 'moment';
import { Paper, Button, TextField, Table, TableHead, TableRow, TableCell, TableBody, Grid, IconButton, Tooltip } from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import GetAppIcon from '@material-ui/icons/GetApp';
import CancelIcon from '@material-ui/icons/Cancel';
import { engineAttachmentsHeader } from '..';
import DialogueWrapper from '../shared/Dialogue';
import { checkApiStatus, getLocalStorageInfo } from '../../../utils_v2';
import { globalDeleteService, globalPostService } from '../../../utils_v2/globalApiServices';
import { displayDateFormat, imgStoragePath } from '../../../constants';
import { EmptyCollection } from '../../../shared';
import { PageLoader } from '../../../shared_elements';
import "../assets/engine_workorder.scss"
const userInfo = getLocalStorageInfo();
const AddEditAttachment = ({ engine,params, setEngine, addEditAttachment, toggleModalFn, attachment, setAttachment }) => {
    const [isLoading, setLoading] = useState({ isPage: false, isButton: false });
    const [previewFile, setPreviewFile] = useState({ modal: false, data: {} });
    const [error, setError] = useState()
    const onAddEditAttachment = () => {
        setLoading({ ...isLoading, isButton: true })
        const { id, name, profile_pic } = userInfo.user
        const payload = new FormData();
        let url
        if (attachment?.files && attachment?.files?.length > 0) { payload.append('file', attachment?.files[0]) }
        if (params.workOrderSlug == undefined) {
            url = `/technical/engine-workorder/file-upload/`;
          } else {
            url = `/technical/engine-workorder/${params.workOrderSlug}/file-upload/`
          }
        globalPostService(url, payload)
            .then(response => {
                if (checkApiStatus(response)) {
                    setAttachment(null)
                    const updatedObj = { attachment: response?.data?.url, name: response?.data?.name, created_at: moment(), description: attachment?.description, user: { id: id, name: name, profile_pic: profile_pic }, }
                    if (engine.attachments?.length > 0) { setEngine({ ...engine, attachments: [...engine.attachments, updatedObj] }) }
                    else { setEngine({ ...engine, attachments: [updatedObj] }) }
                    toggleModalFn();
                }
                setLoading({ ...isLoading, isButton: false })
            })
    }
    const onFieldChange = (keyParam, value) => setAttachment({ ...attachment, [keyParam]: value })
    const handleChipClick = (event) => {
        setLoading({ ...isLoading, isPage: true })
        if (event.endsWith('.xlsx') || event.endsWith('.xls')) {
            let formData = new FormData();
            formData.append('file', attachment?.files.find(item => item?.path === event));
            globalPostService(`camo/preview/file/`, formData)
                .then(response => {
                    if (response?.status === 200 && response?.statusText === "OK") {
                        setLoading({ ...isLoading, isPage: false })
                        setPreviewFile({ modal: true, data: response?.data, })
                    }
                });
        } else {
            setLoading({ ...isLoading, isPage: false })
            setPreviewFile({ modal: true, data: [attachment?.files?.find(item => item?.path === event)], singlePreview: true })
        }
    };
    const handlePreviewClose = () => { setPreviewFile({ ...previewFile, modal: false }) }
    return (
        <>
            <DialogueWrapper open={addEditAttachment.modal} disableButton={attachment === null || !Object.keys(attachment)?.length} toggleModalFn={toggleModalFn} isLoading={isLoading?.isButton} dialogueTitle="Add Attachment" handleSave={onAddEditAttachment} dialogueWidth="400px" buttonLabel="Add">
                <Grid container spacing={1}>
                    <Grid item md={12}>
                        <div>
                            <DropzoneArea
                                acceptedFiles={['text/csv',".xlsx", "application/xls", ",application/excel", , ",application/vnd.ms-excel", 'application/pdf']}
                                filesLimit={1}
                                onDelete={() => setAttachment(null)}
                                maxFileSize={104857600}
                                showPreviewsInDropzone={false}
                                useChipsForPreview={true}
                                showPreviews={true}
                                dropzoneText={<p style={{ fontSize: '13px' }}>Drag & Drop Documents<br /> OR <br /> Click Here<br /></p>}
                                dropzoneClass="drag-drop-cnt"
                                showAlerts={['info', 'error']}
                                alertSnackbarProps={{ anchorOrigin: { vertical: 'top', horizontal: 'right' } }}
                                clearOnUnmount={true}
                                previewChipProps={{
                                    onClick: (event) => {
                                        handleChipClick(event?.target.textContent);
                                    }
                                }}
                                onDrop={(files) => { setAttachment({ ...attachment, 'files': files }); setError({ ...error, 'files': '' }) }}
                            />
                           {(attachment === null || Object?.keys(attachment)?.length === 0) &&  <p><span style={{background:'#ffe160', fontSize:'11px', padding:'2px 3px'}}><span style={{fontWeight: 500}}>Format Support :</span>.pdf, .xls, .xlsx, .csv(Only 1 File at a time) </span></p>}
                        </div>
                        {attachment?.files && attachment?.files?.length > 0 ?
                            <div style={{ marginTop: "12px" }}><TextField
                                InputLabelProps={{ shrink: true }}
                                label='Description'
                                value={attachment.description}
                                onChange={(e) => onFieldChange('description', e.target.value.trim())}
                                fullWidth
                                variant='outlined'
                            /></div> : null}
                    </Grid>
                </Grid>
            </DialogueWrapper>
            <PreviewFileModal previewFile={previewFile} maxWidth="xl" toggleModalFn={handlePreviewClose} />
            {isLoading?.isPage ? <PageLoader /> : null}
        </>
    )
}

const PreviewFileModal = ({ previewFile, toggleModalFn }) => {
    return <DialogueWrapper isPreview={true} maxWidth="lg" open={previewFile.modal} dialogueTitle="Preview" dialogueWidth="1200px" toggleModalFn={toggleModalFn}  >
        {previewFile?.singlePreview ? previewFile?.data?.map((file, index) => (
            <div key={index}>
                {
                    file?.type === 'application/pdf' ? (
                        <object type='text/html' data={URL.createObjectURL(file)} style={{ width: '100%', height: '100vh' }}>
                            <param name='type' value='text/html' />
                        </object>
                    ) : file.type.startsWith('image/') && (
                        <img src={URL.createObjectURL(file)} alt={file.name} style={{ width: '100%', height: '100vh' }} />
                    )
                }
            </div>
        ))
            :
            previewFile?.data?.file_name?.endsWith('.xls') || previewFile?.data?.file_name?.endsWith('.xlsx') || previewFile?.data?.file_name?.endsWith('.docx') || previewFile?.data?.file_name?.endsWith('.doc') ?
                <div>
                    <iframe src={'https://view.officeapps.live.com/op/embed.aspx?src=' + encodeURIComponent(previewFile?.data?.file_url)} width='100%' height={window.innerHeight} frameborder='0'></iframe></div> : null
        }
    </DialogueWrapper>
}
const EngineAttachments = ({ params, engine,updatePermission, setEngine, onMultiFieldChange }) => {
    const [addEditAttachment, setAddEditAttachment] = useState({ modal: false, data: null });
    const [attachment, setAttachment] = useState(addEditAttachment.data);

    const handleMultiDelete = (index, clickData) => {
        const updatedRows = [...engine?.attachments];
        if (params?.workOrderSlug && clickData?.id) {
            globalDeleteService(`technical/engine-workorder/${params.workOrderSlug}/attachment/${clickData?.id}/`, {})
                .then(response => {
                    if (checkApiStatus(response)) {
                        updatedRows.splice(index, 1);
                        setEngine({ ...engine, attachments: updatedRows });
                    }
                })
        } else {
            updatedRows.splice(index, 1);
            setEngine({ ...engine, attachments: updatedRows });
        }

    }
    return (
        <> <div className='project-management-modal'>
            <Grid container style={{ justifyContent: 'right', padding: '8px 4px 4px 0px' }}>
                <Button size='small' color='primary' variant='contained' onClick={() => setAddEditAttachment({ modal: true, data: null })} >ADD Attachment</Button>
            </Grid>
            <Paper square style={{ height: params?.workOrderSlug ? updatePermission ?(window.innerHeight - 339) + 'px' : (window.innerHeight - 309) + 'px' : (window.innerHeight - 355) + 'px', overflow: 'auto', position: '', marginLeft: '' }} >
                <Table stickyHeader style={{ height: 'auto', overflow: 'auto' }} className='mui-table-format' >
                    <TableHead><TableRow>{engineAttachmentsHeader?.map((headerField, index) => <TableCell key={headerField?.label + index} style={{ width: headerField?.width }}>{headerField?.label}</TableCell>)}</TableRow></TableHead>
                    <TableBody>
                        {engine?.attachments?.length > 0 ? engine?.attachments?.map((bodyField, index) => {
                            return (<TableRow key={index + bodyField.name}> <TableCell>{bodyField?.name}</TableCell>
                                <TableCell><TextField
                                    value={bodyField.description}
                                    onChange={(e) => onMultiFieldChange(index, 'description', e.target.value, "attachments")}
                                    fullWidth
                                    variant='outlined'
                                /></TableCell>
                                <TableCell><div className='d-flex align-items-center'> {userInfo?.user?.profile_pic ? <img style={{ borderRadius: "50%" }} height={30} width={30} src={userInfo?.user?.profile_pic} alt="user image" /> : <img src={imgStoragePath + 'user.png'} alt="user image" />} <div style={{ marginLeft: "5px" }}>  {userInfo?.user?.name}<p style={{ fontSize: '11px', opacity: '0.8' }}>{moment().format(displayDateFormat)}</p></div> </div></TableCell>
                                <TableCell><div className='flex-centered' >
                                    <Tooltip title='Download' arrow><a target="_blank" href={bodyField?.attachment} download ><GetAppIcon color='primary' variant="outlined" fontSize="small" /> </a></Tooltip>
                                    <Tooltip title='Remove' arrow><IconButton onClick={() => handleMultiDelete(index, bodyField)}><CancelIcon color='secondary' fontSize="small" /> </IconButton></Tooltip> </div></TableCell>
                            </TableRow>)
                        }) : null}
                    </TableBody>
                </Table>
                {!engine?.attachments.length ? <EmptyCollection title="No attachments found" className="empty-collection-ui-no-padding" /> : null}
            </Paper>
        </div>
            <AddEditAttachment engine={engine} params={params} setEngine={setEngine} addEditAttachment={addEditAttachment} attachment={attachment} setAttachment={setAttachment} toggleModalFn={() => { setAttachment({}); setAddEditAttachment({ modal: false, data: null }) }} />
        </>
    )
}

export default EngineAttachments
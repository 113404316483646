import { checkApiStatus } from "../../utils_v2";
import { globalPutService,globalPostService,globalGetService } from "../../utils_v2/globalApiServices";

export const helperFunc = {
    fetchApi : (url, method = "get", data = {}, successCallback,errorCallback) => {
        const service = method === "post" ? globalPostService : method === "put"? globalPutService : globalGetService;
        service(url, data).then((response) => {
          if (checkApiStatus(response)) {
              successCallback({responseData:response?.data?.data, message :response?.data?.message} );
          }else{
            if(errorCallback){ errorCallback(response?.data?.message)}
            }
        });
      },
     validateAndFormatUrl : (inputUrl) => {
        if (!/^https?:\/\//i.test(inputUrl)) {
            return `https://${inputUrl}`;
        }
        return inputUrl; 
    },
     checkUrl : (url) => { try {
      const newUrl = new URL(url);
      if (!/\.[a-zA-Z]{2,}$/.test(newUrl.hostname)) {
        return false;
      }
      return newUrl.href; 
    } catch (_) {
      return false; 
    }}
}
import React from 'react'
import "./../assets/assetPopover.scss"
const ProjectInfoPopover = ({isProject}) => {
  return (
    <div>
      <div className="info-popover-card">
        <div className='d-flex pb-8 separator-line'> <span className='info-popover-card__title'>Project Status</span>
        </div>
        <div style={{padding : "10px"}} className=" d-flex">
          <ul>
            <li className='heading'> <span className="genral-info status-info"></span><b>Upcoming</b>
              <ul style={{ marginLeft: '5px' }}>
                <li className='sub-heading'> <span className="initiated status-info"></span>Initiated - Project is set up in the system in advance, but the project is not yet started, applicable to all planned projects.</li>
              </ul>
            </li>
            <li className='heading'> <span className="genral-info status-info"></span><b>Ongoing</b>
              <ul style={{ marginLeft: '5px' }}>
                <li className='sub-heading'> <span className="launched status-info"></span>Launched / In Progress - Project is set up in the system in advance, but the project is not yet started, applicable to all planned projects. </li>
                <li className='sub-heading'> <span className="draft status-info"></span>Draft - Project is completed by Rep. and with PM for final QC.</li>
                <li className='sub-heading'> <span className="review status-info"></span>Review - Completed report issued to client for review and feedback.</li>
                <li className='sub-heading'> <span className="approved status-info"></span>Approved</li>
              </ul>
            </li>
            {isProject ? <li className='heading'> <span className="published status-info"></span><b>Published</b></li> : null}
            <li className='heading'> <span className="overdue status-info"></span><b>Overdue</b> - Project end date which has crossed the due date</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default ProjectInfoPopover
export const assetType = { 1: 'MSN', 2: 'ESN', 3: 'APU', 4: 'LG', 5: 'Propeller' }
export const helperConst = {
  LIST: "list",
  EXPORT: "export",
  UTILIZATION: "utilization",
  FILTER: "filter",
  INFINITE_LIST: "infinite_list",
}
export const projectStatusObj={
  Draft : "draft",
  Approved : "approved" ,
  Initiated:"initiated",
  Launched  : "launched",
  "In-Review" :"in-review",
  Completed : "completed",
  Available : "available",
  Leave : "leave"
}
export const manpowerStatusObj ={
 Available :"available",
   "Not Available":"not_available",
   Deployed:"deployed"
}
export const manPowerTableHeader = [
  { label: "Name of Staff", isSort: true, alignPosition: "left" },
  { label: "Projects", isSort: true, alignPosition: "center" },
  { label: "Status", isSort: true, alignPosition: "center" },
  // { label: "", isSort: true, alignPosition: "center" },
];
export const manPowerCollapseTableHeader = [
  { label: "Project Name", isSort: true, alignPosition: "left" },
  { label: "Department", isSort: true, alignPosition: "center" },
  { label: "Location", isSort: true, alignPosition: "center" },
  { label: "Start Date", isSort: true, alignPosition: "center" },
  { label: "End Date", isSort: true, alignPosition: "center" },
];
export const projectTrackerTableHeader = [
  { label: "Project Name", isSort: true, alignPosition: "left" },
  { label: "Asset", isSort: true, alignPosition: "left" },
  { label: "Status", isSort: true, alignPosition: "left" },
  { label: "Remarks", isSort: true, alignPosition: "left" },
  { label: "Start Date", isSort: true, alignPosition: "left" },
  { label: "End Date", isSort: true, alignPosition: "left" },
  ,
];
export const templateObj = {
  name: "",
  description: "",
  file: [],
  type: null,
};
export const apiUrl = {
  project_tracker_api: "technical/project-tracker/",
  manpower_list_api: "technical/manpower-tracker/",
}

export const projectTrackerFilter = {
    status: {
      inputType: 'dropdown',
      placeholder: 'Select Status',
      options: [{id: 'upcoming', name: 'Up Coming'}, {id: 'ongoing', name: 'On Going'}, { id: 'overdue', name: 'Overdue'}, { id: 'completed', name: 'Completed'}],
      labelKey: 'name',
      valueKey: 'id',
      title: 'Status',
      multiple: true
    },
    department: {
      inputType: 'dropdown',
      placeholder: 'Select Department',
      options: [],
      labelKey: 'label',
      valueKey: 'value',
      title: 'Department',
      multiple: true
    },
    project_type: {
      inputType: 'dropdown',
      placeholder: 'Select Project Type',
      options: [],
      labelKey: 'label',
      valueKey: 'label',
      title: 'Project Type',
      multiple: true
    },
    search:{
      inputType: 'text',
      placeholder: 'Search',
      title: 'Search'
    },
    project_lead:{
      inputType: 'text',
      placeholder: 'Search by Lead',
      title: 'Lead'
    },
    project_engineer:{
      inputType: 'text',
      placeholder: 'Search by Engineer',
      title: 'Form User'
    },
    project_no:{
      inputType: 'text',
      placeholder: 'Search by Project No',
      title: 'Project Number'
    },
    project_location:{
      inputType: 'text',
      placeholder: 'Search by Project Location',
      title: 'Project Location'
    },
    serial_number:{
      inputType: 'text',
      placeholder: 'Search by Asset',
      title: 'Asset Serial Number'
    },
    startDate:{
        inputType: 'date',
        title: 'Start Date'
    },
    endDate:{
        inputType: 'date',
        title: 'End Date'
    },
    operator: {
        inputType: 'text',
        placeholder: 'Search by Lessee',
        title: 'Lessee'
    },
    lessor_id: {
        inputType: 'dropdown',
        placeholder: 'Search by Lessor',
        title: 'Lessor',
        options: [],
        labelKey: 'name',
        valueKey: 'id',
        multiple: true
  },
  
  }
export const manPowerFilter = {
'user_type': {inputType: "dropdown",keyParam: "user_type",placeholder: "Select Status",options: [{ id: "available", name: "Available" },{ id: "not_available", name: "Not Available" },{ id: "deployed", name: "Deployed" },],labelKey: "name",valueKey: "id",label: "Status",'title': 'Status',},
  'user': {'inputType': 'text','placeholder': 'Search by Project User','title': 'User',},
  'project_name': {'inputType': 'text','placeholder': 'Search by Project','title': 'Project Name',keyParam: "project_name",},
  'location': {
    'inputType': 'text',
    'placeholder': 'Search by Location',
    'title': 'Location',
    keyParam: "location",
  },
  startDate:{
    inputType: 'date',
    title: 'Start Date'
},
endDate:{
    inputType: 'date',
    title: 'End Date'
},
  department: {
    inputType: "dropdown",
    keyParam: "department",
    placeholder: "Select Department",
    options: [],
    labelKey: "name",
    valueKey: "id",
    title: "Department",
    multiple : true
  },
  category: {
    inputType: "dropdown",
    keyParam: "category",
    placeholder: "Select Category",
    options: [],
    labelKey: "name",
    valueKey: "id",
    title: "Category",
    multiple : true
  },
}




import React from 'react'
import { Grid, Paper, Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { Filter } from '../shared';
import { onCheckPermission } from '../../../utils_v2';
import { ExportManu,  } from "../../../shared";


const FilterExportPanel = (props) => {
  const { filter, disabled,filterOpen,toggleFilterModal, handleExport, handleFilter, children, filterOptions } = props
  return (
    <Paper square className="filter-cta">
      <Grid alignItems="center" container spacing={1}>
        <Grid item xs={9}>
          <Filter
            filter={filter}
            filterMenu={filterOptions}
            getResponseBack={handleFilter}
            toggleFilterModal={toggleFilterModal}
            filterOpen={filterOpen}
          />
        </Grid>
        <Grid item xs={3}>
          <ul className="list-inline project-list-cta d-flex">
            <>
              {onCheckPermission("project_management", "tracker", "EXP") ? (
                <li className="list-inline-item">
                  <ExportManu variant = "outlined" size="small" disabled={disabled} title="Export" files={[{ title: "Excel", extension: "xls", key: "" },]} exportReportFn={handleExport} />
                </li>) : null}
              {children}
            </>
          </ul>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default FilterExportPanel
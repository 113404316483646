import React, { useState } from 'react'
import { Button, TextField, Tooltip } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit';
import { globalPostService } from '../../../globalServices';
import { checkApiStatus } from '../../../utils_v2';

const RemarksPopover = ({ remarks, trackerData, setTrackerData }) => {
  const [edit, setEdit] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [updatedRemarks, setUpdatedRemarks] = useState(remarks?.project_remarks ?? "");
  const handleEdit = () => setEdit(true)
  const handleSave = () => {
    setLoading(true)
    globalPostService(`technical/project/${remarks?.slug}/update-remark/`, { remarks: updatedRemarks })
      .then(response => {
        if (checkApiStatus) {
          setLoading(false)
          let updatedData = trackerData?.map(ele => {
            if (ele.id === remarks?.id) {
              return { ...ele, project_remarks: updatedRemarks }
            } else return ele
          })
          setTrackerData(updatedData)
          setEdit(false)
        }
      })
  }
  const onFieldChange = (value) => setUpdatedRemarks(value)
  return (
    <div >
      <div className="remarks-popover-card">
        <div className='d-flex pb-8'> <span className='remarks-popover-card__title'>Remarks</span>
          {!edit ? <Tooltip title='Edit' arrow>
            <EditIcon color='primary' onClick={handleEdit} fontSize='small' className='pointer' />
          </Tooltip> : ""}
        </div>

         <div style={{height:"155px"}}>
          <div className=" d-flex">
            {edit ?
              <TextField id="project_remarks"  multiline rows="4" value={updatedRemarks !== null ? updatedRemarks : ''} variant="outlined" onChange={(e) => onFieldChange(e.target.value)} /> : <div className='remarks-popover-card__remarks-text'>{updatedRemarks}</div> }
          </div>
          {edit && <div className='remarks-popover-card__action-button'>
            <Button size='small' className='cursor-pointer' variant='outlined' color='primary' onClick={() => setEdit(false)}><div>Cancel</div></Button>
            <Button size='small' className='cursor-pointer' color='primary' variant='contained' disabled={isLoading} onClick={handleSave}><div>Save</div></Button>
          </div>
          }
          </div> 
 
       
      </div>
    </div>
  )
}

export default RemarksPopover
import React, { useState } from "react";
import { Table, TableBody, TableCell, TableHead, TableRow, IconButton, Collapse, TableContainer, Paper, Tooltip } from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { manPowerCollapseTableHeader, manpowerStatusObj, manPowerTableHeader } from "..";
import UserInfoCell from "../components/UserInfoCell";
import { imgStoragePath } from "../../../constants";

const CollapsibleTable = ({ tableData,handleUserDetailModal,handleProjectPopover }) => {
  const [openRows, setOpenRows] = useState("");
  const handleToggleRow = (id) => { if (id === openRows) { setOpenRows("") } else setOpenRows(id); };

  const renderCollapseCell = (collapseData)=>{
    return [collapseData.name,collapseData?.department?.label,collapseData.location,collapseData.start_date,collapseData.end_date || collapseData.expected_end_date]
  }
  return (
    <Paper>
      <Table className="mui-table-format" stickyHeader size="medium">
        <TableHead><TableRow>{manPowerTableHeader.map((thead,index) => { return (<TableCell key={index + "manpowerHeader"} style={{ zIndex: 100}} align={thead.alignPosition}>{thead.label}</TableCell>); })}</TableRow></TableHead>
        <TableBody>
          {tableData?.map((row, index) => (
            <>
              <TableRow key={index + "manpowerTableCell"} style={{ background: row.id === openRows ? "#f3f3f3" : "white" }}   >
                <TableCell style={{ width: "220px", borderBottom: row.id === openRows ? "0px" : null }}>
                  <div className="align-center">
                    <IconButton onClick={() => handleToggleRow(row.id)}>
                      {row.id === openRows ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                    <UserInfoCell user={row} handleUserDetailModal={handleUserDetailModal}/> </div>
                </TableCell>
                <TableCell style={{ width: "250px", borderBottom: row.id === openRows ? "0px" : null }} align="center">
                  <span>
                  {row?.projects?.length > 0 ? `${row?.projects?.length} Project(s)` : `0 Project`}
                  {row?.projects?.length > 0 &&
                      <Tooltip title="Projects Info" arrow>
                        <span>  <img
                          className="user-info-icon"
                          onClick={(event) => handleProjectPopover(event, row?.projects)}
                          src={imgStoragePath + "inv_info_icon.png"}
                          alt=""
                        /></span>
                      </Tooltip>
                    }
                    </span>
                  </TableCell>
                <TableCell style={{ width: "250px", borderBottom: row.id === openRows ? "0px" : null }} align="center">{row?.status ? (
                  <span className={`project-status ${manpowerStatusObj[row.status]} `}
                  >{row?.status}</span>
                ) : null}</TableCell>
              </TableRow>
              {row.id === openRows ? <TableRow >
                <TableCell colSpan={5}
                  style={{
                    background: "#f3f3f3",
                    paddingLeft: !row.id === openRows ? "0px" : "27px",
                    paddingRight: !row.id === openRows ? "0px" : "27px",
                    boxShadow: row.id === openRows ? "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)" : ""
                  }}>
                  <Collapse in={row.id === openRows} timeout="auto" unmountOnExit>
                    <TableContainer style={{ maxHeight: "200px", }}>
                      <Paper><Table style={{ border: "1px solid #d7dbe0" }} className="mui-table-format" size="small" stickyHeader >
                        <TableHead >
                          <TableRow>
                            {manPowerCollapseTableHeader?.map((collapseHeader ,index) => {
                              return <TableCell key={index+ "collapseTableHeader"} style={{ background: " #dbe2f8" }}>
                                {collapseHeader?.label}
                              </TableCell>
                            })}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {row?.projects?.length > 0 && row?.projects.map((detail, index) => (
                            <TableRow key={index + "manpowerCollapseTable"}>
                              {renderCollapseCell(detail)?.map((collapseCell, index) => {
                                return <TableCell key={index + "collapseCell"} className="collapse-table-cell"  >{collapseCell}</TableCell>
                              })}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table></Paper>
                    </TableContainer>
                  </Collapse>
                </TableCell>
              </TableRow> : null}
            </>
          ))}
        </TableBody>
      </Table></Paper>
  );
};

export default CollapsibleTable;